import React, { Suspense, useEffect, useState } from "react";
import { HashRouter, Route, Switch, BrowserRouter } from "react-router-dom";
import { Spinner } from "reactstrap";
import { Auth } from "aws-amplify";
import RestClient from "./components/FeatureAPI/AccessManagement";
import { ActivityTracker } from "./ActivityTracker";
import "./App.scss";
import "./index.css";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <Spinner style={{ width: "3rem", height: "3rem" }} />{" "}
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const ResetPassword = React.lazy(() =>
  import("./views/Pages/ResetPassword/ResetPassword")
);
const SetPassword = React.lazy(() =>
  import("./views/Pages/SetPassword/SetPassword")
);
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));
const SetNewPassword = React.lazy(() =>
  import("../src/containers/DefaultLayout/SetNewPassword")
);

function App(props) {
  useEffect(() => {
    let config = localStorage.getItem("modalConfig");
    if (!config) {
      RestClient.retrieveConfiguration().then((res) => {
        localStorage.setItem(
          "modalConfig",
          JSON.stringify(res.data["ModalsConfiguration"])
        );
      });
    } else {
      // compare local storage config and dynamodb config
      let configArr = JSON.parse(config);
      RestClient.retrieveConfiguration().then((res) => {
        if (res.data["ModalsConfiguration"].length !== configArr.length) {
          localStorage.setItem(
            "modalConfig",
            JSON.stringify(res.data["ModalsConfiguration"])
          );
        } else {
          let flag = true;
          for (let i = 0; i < configArr.length; i++) {
            if (
              res.data["ModalsConfiguration"][i]["ActiveIdleTimeInMinutes"] !==
                configArr[i]["ActiveIdleTimeInMinutes"] ||
              res.data["ModalsConfiguration"][i]["DynamoDBKey"] !==
                configArr[i]["DynamoDBKey"] ||
              res.data["ModalsConfiguration"][i]["IsEnabled"] !==
                configArr[i]["IsEnabled"] ||
              res.data["ModalsConfiguration"][i]["Name"] !==
                configArr[i]["Name"]
            ) {
              flag = false;
              break;
            }
          }

          if (!flag) {
            localStorage.setItem(
              "modalConfig",
              JSON.stringify(res.data["ModalsConfiguration"])
            );
          }
        }
      });
    }
  }, []);

  return (
    <HashRouter>
      <ActivityTracker>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/resetpassword"
              name="ResetPassword Page"
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              exact
              path="/setpassword"
              name="SetPassword Page"
              render={(props) => <SetPassword {...props} />}
            />
            <Route
              exact
              path="/settings/set-new-password"
              name="SetNewPassword Page"
              render={(props) => <SetNewPassword {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </ActivityTracker>
    </HashRouter>
  );
}
export default App;
