import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

// Custom hook to manage activity cards
export const useActivityTracker = () => {
  const [activityCards, setActivityCards] = React.useState(() => {
    // Initialize from localStorage if available
    const saved = localStorage.getItem("activityCards");
    return saved ? JSON.parse(saved) : [];
  });

  useEffect(() => {
    localStorage.setItem("activityCards", JSON.stringify(activityCards));
  }, [activityCards]);

  const formatPathToName = (path) => {
    const pathParts = path.split("/").filter(Boolean);

    if (pathParts.length === 0) return "Home";

    // Handle paths with query parameters or IDs
    return pathParts
      .map((part) => {
        // Check if part contains an ID or UUID
        if (part.includes("=")) {
          const [section, id] = part.split("=");
          // Format the section name
          const formattedSection = section
            .split("-")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

          // Shorten UUID/ID to first 8 characters
          const shortId = id.length > 8 ? id.substring(0, 8) : id;
          return `${formattedSection} - ${shortId}`;
        }

        // Format regular path parts
        return part
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      })
      .join(" - ");
  };

  const updateActivity = (path) => {
    // Don't track login and error pages
    const excludedPaths = [
      "/login",
      "/404",
      "/500",
      "/resetpassword",
      "/setpassword",
    ];
    if (excludedPaths.includes(path)) return;

    setActivityCards((prevCards) => {
      const now = new Date().toISOString();
      const existingCardIndex = prevCards.findIndex(
        (card) => card.path === path
      );

      if (existingCardIndex >= 0) {
        // Update existing card
        const updatedCards = [...prevCards];
        const card = updatedCards[existingCardIndex];
        updatedCards[existingCardIndex] = {
          ...card,
          visits: card.visits + 1,
          lastVisited: now,
        };
        return updatedCards;
      } else {
        // Create new card with formatted name
        const name = formatPathToName(path);

        const newCard = {
          name,
          path,
          icon: getIconForPath(path),
          category: getCategoryForPath(path),
          description: `Track activity for ${name}`,
          visits: 1,
          lastVisited: now,
          firstVisited: now,
        };

        return [...prevCards, newCard].slice(-6); // Keep only latest 6 activities
      }
    });
  };

  const getIconForPath = (path) => {
    const categories = [
      { keywords: ["ec2", "routes"], icon: "fa fa-server" },
      { keywords: ["rds", "cluster"], icon: "fa fa-database" },
      { keywords: ["api"], icon: "fa fa-exchange" },
      { keywords: ["task", "automation"], icon: "fa fa-tasks" },
      { keywords: ["sql", "script"], icon: "fa fa-file-code-o" },
    ];

    for (const { keywords, icon } of categories) {
      if (keywords.some((keyword) => path.includes(keyword))) {
        return icon;
      }
    }

    return "fa fa-clock-o"; // Default icon
  };

  const getCategoryForPath = (path) => {
    const categories = [
      { keywords: ["ec2", "routes"], category: "infra" },
      { keywords: ["rds", "cluster"], category: "db" },
      {
        keywords: ["api", "task", "schedule", "automation"],
        category: "automation",
      },
    ];

    for (const { keywords, category } of categories) {
      if (keywords.some((keyword) => path.includes(keyword))) {
        return category;
      }
    }

    return "all"; // Default category
  };

  return {
    activityCards,
    updateActivity,
  };
};

// ActivityTracker component to wrap the application
const ActivityTrackerComponent = ({ children, location }) => {
  const { updateActivity } = useActivityTracker();

  useEffect(() => {
    // Remove hash from path if present
    const path = location.pathname;
    updateActivity(path);
  }, [location.pathname]);

  return children;
};

export const ActivityTracker = withRouter(ActivityTrackerComponent);

// Modified Dashboard component
export const enhanceDashboard = (DashboardComponent) => {
  const EnhancedDashboard = (props) => {
    const { activityCards } = useActivityTracker();

    // Convert activity cards to quick links format
    const activityQuickLinks = activityCards.map((card) => ({
      name: card.name,
      path: card.path,
      icon: card.icon,
      category: card.category,
      description: card.description,
      stats: `${card.visits} Visits`,
    }));

    // Ensure props.quickLinks exists and is an array
    const originalQuickLinks = Array.isArray(props.quickLinks)
      ? props.quickLinks
      : [];

    // Safely combine the quick links
    const combinedQuickLinks = [...originalQuickLinks, ...activityQuickLinks];

    const enhancedProps = {
      ...props,
      quickLinks: combinedQuickLinks.reverse(),
    };

    return <DashboardComponent {...enhancedProps} />;
  };

  return withRouter(EnhancedDashboard);
};

export default {
  ActivityTracker,
  useActivityTracker,
  enhanceDashboard,
};
